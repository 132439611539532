export const IB_SOFTWARE = {
  modifier: null,
  title: 'Software is a Liquid Asset',
  descr:
    'Decentralized technologies represent a new paradigm for coordination technology and unlock new models for positive-sum economies and pro-social innovation. In Web3, Software is a Liquid Asset. ',
  cta: [
    {
      name: 'Invest',
      attrs: {
        to: '/investment-disiplines/',
        className: 'btn btn-primary',
      },
    },
  ],
  img: '/ibox/software.svg',
}

export const IB_HOW_IT_WORKS = {
  modifier: 'reverse',
  title: 'How it works',
  descr:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget etiam felis habitasse sit neque adipiscing at sit. Sapien proin a, varius vel. Posuere proin ornare sollicitudin sed ultrices. Nulla phasellus faucibus turpis sagittis ac donec id.',
  cta: [
    {
      name: 'Learn more',
      attrs: {
        to: '/how-it-works/',
        className: 'btn btn-primary',
      },
    },
  ],
  img: '/ibox/how-it-works.svg',
}

export const IB_THE_OPPORTUNITY = {
  modifier: null,
  title: 'The Opportunity',
  descr:
    'Blockchain technology and Web3 software are rewriting opportunities for innovation, democratizing value creation and exchange, and merging common purpose with common enterprise by aligning incentives. Senary Commonwealth creates, invests, and utilizes software as a liquid asset to contribute to our greater, shared humanity while reversing today’s exploitative digital infrastructure. Our thesis is a certainty: meaningful contributions during this dawn of human progress will yield immense rewards for those invested in its promise.',
  cta: [
    {
      name: 'Our thesis',
      attrs: {
        to: '/',
        className: 'btn btn-primary',
      },
    },
    {
      name: 'Invest with us',
      attrs: {
        to: '/investment-disiplines/',
        className: 'btn btn-primary',
      },
    },
  ],
  img: '/ibox/opportunity.svg',
}

export const IB_CAPITAL_INVESTMENTS = {
  modifier: null,
  title: 'Capital Investments',
  descr:
    'Capital partners with foudners and emerging networks at the earliest stage to accelerate the growth and coordination of emergent crypto economies.',
  cta: [
    {
      name: 'Invest with us',
      attrs: {
        to: '/investment-disiplines/',
        className: 'btn btn-primary',
      },
    },
  ],
  img: '/ibox/capital-investment.svg',
}

export const IB_TRADING = {
  modifier: null,
  title: 'Trading',
  descr:
    'Algorithmically managing a liquid book of crypto assets, our DeFi-native trading practice employs dedicated strategies  to maxamize yeild and expliot risk - return assymetires that we uncover. ',
  cta: [
    {
      name: 'Invest with us',
      attrs: {
        to: '/investment-disiplines/',
        className: 'btn btn-primary',
      },
    },
  ],
  img: '/ibox/trading.svg',
}

export const IB_LABS_DAO = {
  modifier: null,
  title: 'Labs DAO',
  descr:
    '<p>Commonwealth incubated Labs DAO as a decentralized innovation community accelerating the global development of crypto economies. </p> <p>Labs DAO aims to accelerate the development of community infrastructure through novel coordination frameworks designed specifically to maximise the value of tokenized software’s native composability. </p>',
  cta: [
    {
      name: 'Learn more',
      attrs: {
        to: '/how-it-works/',
        className: 'btn btn-primary',
      },
    },
  ],
  img: '/ibox/labs-dao.svg',
}

export const IB_MEMBER_PORTAL = {
  modifier: null,
  title: 'Private Member Portal',
  descr:
    'A token-gated community space for full on-chain transparency into asset details, access to exclusive media content and resources, hosted forums and TBA live events.',
  cta: [],
  img: '/ibox/member-portal.svg',
}

export const IB_HYPERLIQUIDITY = {
  modifier: null,
  title: 'Hyperliquidity',
  descr: 'Lightweight lockups and token liquidity offer freedom to seeking to scale thier COMMON holdings up or down.',
  cta: [],
  img: '/ibox/hyperliquidity.svg',
}

export const IB_SHARED_UPSIDE = {
  modifier: null,
  title: 'Shared Upside',
  descr: "Purchase tokenizied shares of Commonwealth's inivestments into the next era of digital economies.",
  cta: [],
  img: '/ibox/shared-upside.svg',
}

export const IB_SLA_1 = {
  modifier: null,
  title: null,
  descr:
    '<p>Web2 has created a lot of economic value, however its models remain exploitative, and that value rarely accrues to those who actually create it. </p> <p>As such, it’s become unsustainable and its affects undesirable, stuffed to the brim with “vulture capital” money that more often maligns users than it does align with their values and best interests. </p> <p>Ask yourself honestly, of all the VC-backed startups and loosely deployed venture capital, how many of these startups actually create sustainable and long-term value for our society? How many are worth the new problems they create? We argue not many, and that there must be a better way.</p>',
  cta: [],
  img: '/ibox/sla-1.svg',
}

export const IB_SLA_2 = {
  modifier: null,
  title: null,
  descr:
    '<p>SLA is our call to this new era for mainstream public computing. As you read this, Web3’s enabling technology is creating a necessary shift in the economic models underpinning our digital lives, which as it should, faithfully advances the internet’s founding values – of abundance, privacy and sovereignty. </p><p>In this new era of decentralized computing, software is both more flexible and fluid than ever before. It embodies public infrastructure, and to develop decentralized software, to distribute it, use it, invest in it and create with it is all to manage this liquid asset.</p><p>In Web3, Software is a Liquid Asset.</p>',
  cta: [],
  img: '/ibox/sla-2.svg',
}

export const IB_SLA_3 = {
  modifier: null,
  title: null,
  descr:
    '<p>A token example is the token itself. Tokens are flexible: We have tokens as cryptocurrencies, tokens as shares of equity, tokens as novel debt instruments, tokens representing authority in new governance models, game tokens, loyalty tokens, collectible tokens, tokens of tokenized physical assets, tokens in synthetic assets, tokens representing new structures and ideas. Any single token can embody any number of these characteristics, and more.</p>',
  cta: [],
  img: '/ibox/sla-3.svg',
}

export const IB_SLA_4 = {
  modifier: null,
  title: null,
  descr:
    '<p>Tokens are fluid: Their functional characteristics evolve in time or context. Novel incentives promote the adoption of open-source standards, and tokens to flow seamlessly amidst a vast ecosystem of composable software, including many new and powerful crypto-economic mechanisms. With these technologies, we can buy tokens with tokens, borrow them, pledge them, swap them, mint dollars against them (you read that correctly), auction them or donate them.</p>',
  cta: [],
  img: '/ibox/sla-4.svg',
}

export const IB_SLA_5 = {
  modifier: null,
  title: null,
  descr:
    '<p>So, whether the token itself is the very point of the product or merely a behind-the-scenes implementation detail, at the heart of most every decentralized technology lay a token — the token of token economics. To engineer decentralized software is to engineer economics; innovation’s meter is fed with tokens.</p>',
  cta: [],
  img: '/ibox/sla-5.svg',
}

export const IB_SLA_6 = {
  modifier: null,
  title: null,
  descr:
    '<p>But tokens don’t just feed innovation, they breed it too.Today, well-engineered token systems are aligning vales and incentives without centralized control, and doing so more efficiently than ever before — at seemingly limitless scale and with de-minimis risk and cost. Projects form freely and define value in any way they’d like, engineering their incentives and economics accordingly. </p><p>Rigid categories like owner, developer, customer, and user blend together. Concepts like sales, product, marketing, distribution, partnerships, and investor become hazy and remote. </p>',
  cta: [],
  img: '/ibox/sla-6.svg',
}

export const IB_SLA_7 = {
  modifier: null,
  title: null,
  descr:
    '<p>As ownership structures are re-aligned and new models for decentralized cooperation, governance and value creation emerge, we’re all encouraged to refactor our mental models, and we’re all better for it. These innovations and their design space are vast, their evident advantages now unavoidable, and as an accelerant to human progress, their role is undeniable.</p><p>This new class of highly composable and superfluid digital assets grow exponentially every day, and with each development, we’re challenged again-and-again to reimagine the who, when, how and why of software development. </p>',
  cta: [],
  img: '/ibox/sla-7.svg',
}

export const IB_SLA_8 = {
  modifier: null,
  title: null,
  descr:
    '<p>Dedicated to the advancement of decentralized technologies and the positive-sum economies they enable, Commonwealth aggressively invests in these new systems, via new economics and in their uncompromising growth. Ours included, Web3’s meaningful innovations are deployed directly to ‘main net’ — itself paradigm shift. Join us in building a better way.</p>',
  cta: [],
  img: '/ibox/sla-8.svg',
}
